export const VALIDGUESSES = [
  'सड़क',
  'बटन',
  'फसल',
  'कमल',
  'महल',
  'उछल',
  'टहल',
  'मगर',
  'अगर',
  'नगर',
  'पहर',
  'लहर',
  'खबर',
  'नहर',
  'पहर',
  'लहर',
  'भटक',
  'चमक',
  'लपक',
  'पटक',
  'भवन',
  'हवन',
  'पवन',
  'भजन',
  'जलन',
  'नमन',
  'दमन',
  'सफर',
  'मटर',
  'इधर',
  'उधर',
  'बटन',
  'जतन',
  'नक़ल',
  'अकल',
  'शकल',
  'सफल',
  'बहन',
  'दहन',
  'नरम',
  'गरम',
  'नमक',
  'भगत',
  'पलक',
  'शहद',
  'कलश',
  'गगन',
  'कड़क'
]
