export const WORDS = [
  "भवन",
  "कमल",
  "जतन",
  "भगत",
  "लहर",
  "खबर",
  "सड़क",
  "बटन",
  "अकल",
  "नमन",
  "पहर",
  "बहन",
  "सफल",
  "लपक",
  "भजन",
  "नहर",
  "नरम",
  "पलक",
  "दमन",
  "नगर",
  "इधर",
  "उधर",
  "लहर",
  "हवन",
  "मगर",
  "भटक",
  "नमक",
  "बटन",
  "दहन",
  "शहद",
  "पहर",
  "नक़ल",
  "चमक",
  "टहल",
  "महल",
  "पटक",
  "उछल",
  "जलन",
  "सफर",
  "गरम",
  "फसल",
  "पवन",
  "भरत",
  "कलम",
  "कलश",
  "गगन",
  "कड़क"
]
